// DataContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import Papa, { ParseResult } from 'papaparse';
import { feature } from 'topojson-client';
import { FeatureCollection, GeoJsonObject } from 'geojson';
import { useGlobalActiveGeography } from '../data/StatusStore';
import { crosswalkPUMAtoCD } from '../components/utilities/Utilities';
import { cross } from 'd3';

// Define the type for a row in your CSV data
// Update this interface according to the actual structure of your CSV data
export interface FlatFileCsvData {
  meta: any;
  dataJSON: any;
  dataArray: any;
  keyIndexDataArray: any;
}

// Define the type for your context data
interface DataContextType {
  dataActive: FlatFileCsvData | null;
  dataTract: FlatFileCsvData | null;
  dataPUMA: FlatFileCsvData | null;
  dataNYC: FlatFileCsvData | null;
  dataCD: FlatFileCsvData | null;
  dataBoro: FlatFileCsvData | null;
  dataSorter: any | null;
  geoJsonDataGlobe: FeatureCollection | null;
  geoJsonDataTract: any;
  geoJsonDataPUMA: FeatureCollection | null;
  isLoadingTract: boolean;
  isLoadingPUMA: boolean;
  isLoadingNYC: boolean;
  isLoadingCD: boolean;
  isLoadingBoro: boolean;
  isLoadingSorter: boolean;
  isLoadingTopology: boolean;
  isLoadingGlobeTopology: boolean;
  error: string | null;
}

interface DataProviderProps {
  children?: any; // ReactNode;
  title: string; // An example of an additional prop
}

// Create the context with initial default value
const DataContext = createContext<DataContextType>({ 
  dataActive: null, 
  dataTract: null, 
  dataPUMA: null, 
  dataNYC: null, 
  dataCD: null, 
  dataBoro: null, 
  dataSorter: null, 
  geoJsonDataTract: null, 
  geoJsonDataGlobe: null, 
  geoJsonDataPUMA: null, 
  isLoadingTract: true, 
  isLoadingPUMA: true, 
  isLoadingNYC: true, 
  isLoadingCD: true, 
  isLoadingBoro: true, 
  isLoadingSorter: true, 
  isLoadingTopology: true, 
  isLoadingGlobeTopology: true, 
  error: null 
});

// Create a provider component
export const DataProvider: React.FC<DataProviderProps> = ({ children, title }) => {
  const [dataActive, setDataActive] = useState<FlatFileCsvData | null>(null);
  const [dataTract, setDataTract] = useState<FlatFileCsvData | null>(null);
  const [dataPUMA, setDataPUMA] = useState<FlatFileCsvData | null>(null);
  const [dataCD, setDataCD] = useState<FlatFileCsvData | null>(null);
  const [dataNYC, setDataNYC] = useState<FlatFileCsvData | null>(null);
  const [dataBoro, setDataBoro] = useState<FlatFileCsvData | null>(null);
  const [dataSorter, setDataSorter] = useState<any | null>(null);
  const [geoJsonDataGlobe, setGeoJsonDataGlobe] = useState<any>(null);
  const [geoJsonDataTract, setGeoJsonDataTract] = useState<FeatureCollection  | null>(null);
  const [geoJsonDataPUMA, setGeoJsonDataPUMA] = useState<FeatureCollection  | null>(null);
  const [crosswalkedPUMAData, setCrosswalkedPUMAData] = useState<any>(null);
  const [rawCDData, setRawCDData] = useState<any>(null);
  const [isLoadingTract, setIsLoadingTract] = useState<boolean>(true);
  const [isLoadingPUMA, setIsLoadingPUMA] = useState<boolean>(true);
  const [isLoadingNYC, setIsLoadingNYC] = useState<boolean>(true);
  const [isLoadingCD, setIsLoadingCD] = useState<boolean>(true);
  const [isLoadingBoro, setIsLoadingBoro] = useState<boolean>(true);
  const [isLoadingSorter, setIsLoadingSorter] = useState<boolean>(true);
  const [isLoadingTopology, setIsLoadingTopology] = useState<boolean>(true);
  const [isLoadingGlobeTopology, setIsLoadingGlobeTopology] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const activeGeography = useGlobalActiveGeography();

  useEffect(() => {
    console.log('Active geography:', activeGeography);
    switch (activeGeography) {
      case 'Tract':
        setDataActive(dataTract);
        break;
      case 'PUMA':
        //setDataActive(dataPUMA); // PUMA data is now crosswalked to CD
        setDataActive(dataCD);
        break;
      case 'NYC':
        setDataActive(dataNYC);
        break;
      case 'CD':
        setDataActive(dataCD);
        break;
      case 'Boro':
        setDataActive(dataBoro);
        break;
      default:
        setDataActive(null);
        break;
    }
  }, [activeGeography]);

  useEffect(() => {
    console.log('B081824 crosswalkedPUMAData:', crosswalkedPUMAData);
    console.log('B081824 rawCDData:', rawCDData);
    if (crosswalkedPUMAData && rawCDData) {
        rawCDData.map((row: any, indexCD:number) => {
          console.log('B081824 row:', row);
          let _pumaRow = crosswalkedPUMAData.filter((crosswalkedRow: any, index:number) => {
            if (row.GEO_ID !== ""){
              return crosswalkedRow.GEO_ID === row.GEO_ID;
            }else{
              return indexCD === index;
            }
          })[0];
          console.log('B081824 _pumaRow:', _pumaRow);
          if (_pumaRow){
            Object.keys(_pumaRow).forEach((key: string) => {
              row[key] = _pumaRow[key];
            });
          };
        });
        const concatPUMA_CD = rawCDData; //rawCDData.concat(crosswalkedPUMAData);
        console.log('A081824 concatPUMA_CD:', concatPUMA_CD);
        const transformedPUMA_CD = transformData(concatPUMA_CD);
        console.log('A081824 transformedPUMA_CD:', transformedPUMA_CD);
        setDataPUMA(transformedPUMA_CD);
        setDataCD(transformedPUMA_CD);
        setIsLoadingCD(false);
        setIsLoadingPUMA(false);
    }
  }, [rawCDData, crosswalkedPUMAData]);

  useEffect(() => {
    // Example CSV file loading and parsing
    // Ensure to replace "path/to/your/data.csv" with the actual path to your CSV file
    Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - Tract.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        const transformedData = transformData(result.data);
        setDataActive(transformedData); // Tract is default upon load
        setDataTract(transformedData);
        setIsLoadingTract(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingTract(false);
      }
    });
    Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - Puma.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        console.log('C081624 PUMA data raw:', result.data);
        const _crosswalkedPUMAData = crosswalkPUMA(result.data);
        setCrosswalkedPUMAData(_crosswalkedPUMAData);
        //const transformedData = transformData(_crosswalkedPUMAData);
        //console.log('C081624 PUMA data loaded:', transformedData);
        //setDataPUMA(transformedData);
        //setIsLoadingPUMA(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingPUMA(false);
      }
    });
    Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - NYC.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        const transformedData = transformData(result.data);
        setDataNYC(transformedData);
        setIsLoadingNYC(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingNYC(false);
      }
    });
    Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - CD.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        setRawCDData(result.data);
        //const transformedData = transformData(result.data);
        //console.log('C081624 CD data loaded:', transformedData);
        //setDataCD(transformedData);
        //setIsLoadingCD(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingCD(false);
      }
    });
    Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - Boro.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        const transformedData = transformData(result.data);
        setDataBoro(transformedData);
        setIsLoadingBoro(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingBoro(false);
      }
    });
    Papa.parse<FlatFileCsvData>("/data/tabular/NYCDATA2GO_sorter.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        //const transformedData = transformData(result.data);
        setDataSorter(result.data);
        setIsLoadingSorter(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingSorter(false);
      }
    });
    fetch('/data/geo/PUMA_TRACT_2010.json')
    .then(response => response.json())
    .then((topojsonData: any) => {
      console.log('Topojson data loaded:', topojsonData);
      const geoJsonTract: FeatureCollection = feature(topojsonData, topojsonData.objects.TRACT_2010) as any;
      const geoJsonPUMA: FeatureCollection = feature(topojsonData, topojsonData.objects.PUMA_2010) as any;
      setGeoJsonDataTract(geoJsonTract);
      setGeoJsonDataPUMA(geoJsonPUMA);
      setIsLoadingTopology(false);
    })
    .catch((err) => {
      console.error('Error loading the topojson data:', err)
      setError(err.message);
      setIsLoadingTopology(false);
    });
    fetch('/data/geo/worldCleanGeoJSONCleaned.json')
    .then(response => response.json())
    .then((topojsonGlobeData: any) => {
      console.log('061024 Topojson globe data loaded:', topojsonGlobeData);
      const geoJsonGlobe: FeatureCollection = feature(topojsonGlobeData, topojsonGlobeData.objects.collection) as any;
      setGeoJsonDataGlobe(geoJsonGlobe);
      setIsLoadingGlobeTopology(false);
    })
    .catch((err) => {
      console.error('Error loading the global topojson data:', err)
      setError(err.message);
      setIsLoadingGlobeTopology(false);
    });
  }, []);

  function crosswalkPUMA(rawData: any[]): any {
    console.log("B081624, rawData", rawData);
    let crossWalkedData = rawData.flatMap((row: any, i: number) => {
      row["GEO_ID_PUMA"] = row["GEO_ID"];
      if (row.GEO_ID === "") {
        console.log("081624, row", row);
        return row;  // Will be flattened to a single element
      } else {
        console.log("081624, row", row);
        let _CDs = crosswalkPUMAtoCD(row["GEO_ID"]);
        console.log("A081624, _CDs", _CDs);
        console.log("A081624, row", row);
        if (_CDs.length > 1) {
          const copies = _CDs.map((cd: string) => {
            console.log("A081624, cd", cd);
            const copy = { ...row };
            console.log("A081624, copy", copy);
            copy["GEO_ID"] = cd;
            return copy;
          });
          return copies;  // Array of copies will be flattened into the result
        } else {
          row["GEO_ID"] = _CDs[0];
          return row;  // Will be flattened to a single element
        }
      }
    });
  
    console.log("B081624, crossWalkedData", crossWalkedData);
    return crossWalkedData;
  }
  

  function transformData(rawData: any[]): FlatFileCsvData {
    let meta: { [key: string]: any } = {};
    let dataJSON: { [key: string]: any } = {};
    let dataArray: { [key: string]: any[] } = {};
    let keyIndexDataArray: { [key: string]: any[] } = {};
    console.log("A081824 rawData", rawData);
    rawData.forEach((row:any, i:number) => {
      if (row.GEO_ID === ""){
        const _key = !(row["Base Variable"] === "") ? row["Base Variable"] : `Row_${i}`;
        //console.log("A081824 _key", _key);
        //console.log("A081824 row", row);
        if (meta[_key]) {
          meta[_key] = { ...meta[_key], ...row };
        } else {
          meta[_key] = row;
        }
      }else{
        Object.keys(row).forEach(key => {
          // Check if the value is a number by trying to parse it
          let _val = row[key];
          if (key !== "GEO_ID"){
            //console.log(key);
            const parsedValue = parseFloat(_val);
            // If the parsed value is a number and is not NaN, use it; otherwise, keep the original value
            _val = !isNaN(parsedValue) ? parsedValue : row[key];
            row[key] = _val;
          }
          dataArray[key] ? dataArray[key].push({ id: row["GEO_ID"], value: _val }) : dataArray[key] = [{ id: row["GEO_ID"], value: _val }];
        });
        dataJSON[row.GEO_ID] = row;
      }
    });
    
    Object.keys(dataArray).forEach(key => {
      dataArray[key].sort((a, b) => {
        let _a = a.value;
        let _b = b.value;
        if (a.value === "n/a" ){
          _a = -999999999
        }
        if (b.value === "n/a" ){
          _b = -999999999
        }
        return _a - _b
      });
      let histMax = -Infinity; // Start with the lowest possible number
      let histMin = Infinity; // Start with the highest possible number
      let histNA = 0; // Count of "n/a" values
      let histLength = 0; // Count of "n/a" values
      let total = 0; // Count of "n/a" values
      const keyIndex = dataArray[key].reduce((accumulator:any, currentValue:any, index:number) => {
        if (currentValue.value === "n/a") {
          histNA += 1;
        } else {
          const value = parseFloat(currentValue.value);
          total += value;
          if (!isNaN(value) && value > histMax) {
        histMax = value;
          }
          if (!isNaN(value) && value < histMin) {
        histMin = value;
          }
        }
        accumulator[currentValue.id] = {value: currentValue.value, index: index};
        return accumulator;
      }, {});

      keyIndexDataArray[key] = keyIndex;
      if (!meta["max_value"]) { meta["max_value"] = {} };
      if (!meta["min_value"]) { meta["min_value"] = {} };
      if (!meta["na_count"]) { meta["na_count"] = {} };
      if (!meta["length"]) { meta["length"] = {} };
      if (!meta["avg"]) { meta["avg"] = {} };
      meta["max_value"][key] = histMax;
      meta["min_value"][key] = histMin;
      meta["na_count"][key] = histNA;
      meta["length"][key] = dataArray[key].length;
      const avg = total / dataArray[key].length;
      meta["avg"][key] = avg;
    });
    return {"meta": meta, "dataJSON": dataJSON, "dataArray": dataArray, "keyIndexDataArray": keyIndexDataArray};
  }
  
  return (
    <DataContext.Provider value={{ 
      dataActive, dataTract, dataPUMA, dataNYC, dataCD, dataBoro, dataSorter, geoJsonDataPUMA, geoJsonDataTract, geoJsonDataGlobe,
      isLoadingTract, isLoadingPUMA, isLoadingNYC, isLoadingCD, isLoadingBoro, isLoadingSorter, isLoadingTopology, isLoadingGlobeTopology, error }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the context
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
