import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { DataProvider } from './data/DataContext';
import StatExplorer from './views/StatExplorer';
import Dashboards from './views/Dashboards';
import Comparisons from './views/Comparisons';
import MoALogo from './assets/icons/MoALogo.svg';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStatusStoreNavigation } from './data/StatusStore'; // Correct import path

function Navigation() {
  const navigate = useNavigate();
  const location = useLocation();

  // Initialize the StatusStore with navigation and location
  useStatusStoreNavigation();

  // Extract page from URL search parameters
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || 'explorer';

  // Log the current page for debugging
  useEffect(() => {
    console.log("Current page:", currentPage);
  }, [currentPage]);

  // Function to change page and preserve other parameters in order
  const changePage = (newPage: string) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('page', newPage); // Update the 'page' parameter

    // Construct the URL with parameters in the correct order
    const paramsOrder = ['page', 'lat', 'lng', 'zoom', 'selectedId', 'activeGeography'];
    const orderedParams = new URLSearchParams();
    
    paramsOrder.forEach(param => {
      const value = newSearchParams.get(param);
      if (value) orderedParams.set(param, value);
    });

    navigate(`/?${orderedParams.toString()}`, { replace: true });
  };

  return (
    <>
      <div className="top-navbar">
        <div className="menu-toggle" onClick={() => changePage('menu')}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <img src={MoALogo} alt="Measure of America" className="moa-logo" />
        <div className="navbar-title">NYC<b>DATA</b>2<b>GO</b></div>
        <div className="magnify-search-button" onClick={() => changePage('search')}>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.3337" cy="11.6667" r="9.91667" fill="none" stroke="black" strokeWidth="3.5" />
            <path d="M2 28.0001L11.3333 18.6667" stroke="black" strokeWidth="3.5" strokeLinecap="round" />
          </svg>
        </div>
      </div>
      <div className="sidebar">
        <ul className="nav-links">
          <li onClick={() => changePage('explorer')}>STAT EXPLORER</li>
          <li onClick={() => changePage('dashboard')}>DASHBOARDS</li>
          <li onClick={() => changePage('compare')}>COMPARISONS</li>
        </ul>
      </div>
      <Routes>
        <Route path="/" element={currentPage === 'explorer' ? <StatExplorer /> : currentPage === 'dashboard' ? <Dashboards /> : <Comparisons />} />
      </Routes>
    </>
  );
}

function App() {
  const [display, setDisplay] = useState<string>("menu"); // menu, search
  const [resultsToDisplay, setResultsToDisplay] = useState<boolean>(false); // Controls search results display
  const [displayContext, setDisplayContext] = useState<string>("closed"); // closed, menuOpen, searchOpen

  const toggleMenu = () => {
    // Using state variables in the return for this component will cause costly re-renders, 
    // lets do it the old-fashioned way here.
    const mainContent = document.querySelector('.main-content');
    if (mainContent) {
      setDisplay(displayContext === "closed" ? "menu" : display);
      setDisplayContext(displayContext === "closed" ? "menuOpen" : "closed");
      mainContent.classList.toggle('show-menu'); // Toggle the 'show-menu' class directly
    }
  };

  const toggleSearch = () => {
    // Using state variables in the return for this component will cause costly re-renders, 
    // lets do it the old-fashioned way here.
    const mainContent = document.querySelector('.main-content');
    if (mainContent) {
      setDisplay(displayContext === "closed" ? "search" : display);
      setDisplayContext(displayContext === "closed" ? "searchOpen" : "closed");
      mainContent.classList.toggle('show-menu'); // Toggle the 'show-menu' class directly
    }
  };

  return (
    <DataProvider title="NYC Data2Go">
      <Router>
        {/* Ensure all components using Router hooks are within the Router context */}
        <div className="top-navbar">
          <div className="menu-toggle" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div>
            <img src={MoALogo} alt="Measure of America" className="moa-logo" />
          </div>
          <div className="navbar-title">NYC<b>DATA</b>2<b>GO</b></div>
          <div className="magnify-search-button"
            onClick={() => {
              console.log('Search button clicked'); // Logging button click for debug
              toggleSearch();
            }}
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18.3337" cy="11.6667" r="9.91667" fill="none" stroke="black" strokeWidth="3.5" />
              <path d="M2 28.0001L11.3333 18.6667" stroke="black" strokeWidth="3.5" strokeLinecap="round" />
            </svg>
          </div>
        </div>
        <div className={`search-results ${(displayContext === "searchOpen" && resultsToDisplay) ? "search-results-show" : "search-results-hide"}`}>
          <div className="search-results-inner">
            Search results will go here
          </div>
        </div>
        <div className={`main-content`}>
          <Navigation /> {/* Navigation is within the Router */}
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
