import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import Colors from '../utilities/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useGlobalHoveredId, useGlobalSelectedId } from '../../data/StatusStore';

interface BaseDataItem {
  id: string;
  value: number;
}

interface DodgeDataItem extends BaseDataItem {
  x: number;
  y: number;
  next: DodgeDataItem | null;
}

interface SwarmChartDynamicProps {
  dataArray: BaseDataItem[];
  histMax: number;
  histMin: number;
  histNA: number;
  histLength: number;
  chartId: string;
  nodes: DataItem[];
}

interface DataItem {
  id: string;
  value: number;
  x?: number;
  y?: number;
}

const SwarmDynamic: React.FC<SwarmChartDynamicProps> = ({
  dataArray,
  chartId,
  histMax,
  histMin,
  histNA,
  histLength,
  nodes,
}) => {
  const width = "100%";
  const height = "100%";
  const svgRef = useRef<SVGSVGElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const radius = dataArray 
    ? dataArray.length > 100 
      ? 1 
      : 4
    :1; // Radius of the circles on the plot
  const padding = dataArray
    ? dataArray.length > 100 
      ? .25 
      : 1
    :.25; // Padding between circles on the plot

  const hoveredId = useGlobalHoveredId();
  const selectedId = useGlobalSelectedId();

  useEffect(() => {
    const updateContainerWidth = () => {
      if (svgRef.current) {
        setContainerWidth(svgRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener('swarn_resize', updateContainerWidth);
    updateContainerWidth(); // Initial update

    return () => window.removeEventListener('swarm_resize', updateContainerWidth);
  }, [hoveredId]);

  return (  <>
    {nodes.map((node, i) => {   
      const isSelected = node.id === selectedId;
      const isHovered = node.id === hoveredId;
      const fill = isSelected ? Colors.highlightSelect : isHovered ? Colors.highlightHover : 'none'; // 'none' for transparent or any default non-highlight color
      
      if (!isSelected && !isHovered) return null; // Skip rendering if not selected or hovered
      const color = Colors.highlightHover; //getColorQuintile(i, histNA, histLength);
      //console.log("050924 containerWidth, nodes.length", containerWidth, nodes.length)
      return (
        <div 
        key={`swarm_${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}`}
        className='swarm-container swarm-container-dynamic' 
        style={{
          position: "absolute",
          top: "0px",
          width:"100%",
        }}>
            <div
              className={`hist-label-rect ${isSelected ? 'popup-selected' : 'popup-hovered'}`}
              key={`swarm_${isSelected ? 'selected' : 'hovered'}_${chartId}_${i}_box`}
              style={{
                width:"100px",
                height:"30px",
                backgroundColor: "#ffffff",
                border: `1px solid ${fill}`,
                position: "absolute",
                fontSize: "8px",
                textAlign: "center",
                left:node.x ? `calc(${node.x}% - 50px`: "0", //`${(i * (containerWidth / nodes.length)) - 50}px`,
              }}
            >{node.id}<br/>{node.value}</div>
          <svg ref={svgRef} width={width} height={height} className="swarm-chart-dynamic">
                <circle
                  key={node.id}
                  cx={node.x 
                    ? `${node.x}%` 
                    : 0}
                  //cx={node.value ? `${(((node.value - histMin) / (histMax - histMin)) * 100)}%` : 0}
                  cy={node.y 
                    ? `${((parseInt(height) / 2 - radius - padding - node.y) / parseInt(height)) * 100}%`
                    : `${((parseInt(height) / 2 - radius - padding) / parseInt(height)) * 100}%`} // Adjust y position based on radius and padding
                  //cy={parseInt(height) / 2 - radius - padding - node.y} // Adjust y position based on radius and padding
                  //cy={`${node.y / 2}%)`} // Adjust y position based on radius and padding
                  //cy={`calc(${parseInt(height) / 2 } - ${node.y}%)`} // Adjust y position based on radius and padding
                  r={radius}
                  fill={fill}
                />

          </svg>
        </div>
      )})}
      </>);
};

export default SwarmDynamic;
