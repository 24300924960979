import React, { FC, useRef, useState, useEffect, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';
import { ChartCard, ChartCardProps } from './ChartCard'; // Adjust the import path as necessary
import { useInView } from 'react-intersection-observer';
import Colors from '../utilities/Colors'; // Adjust the import path as necessary
import { useGlobalActiveCategory, useGlobalActiveSubCategory, onHoverHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalActiveGeography } from '../../data/StatusStore'; // Adjust the import path as necessary
import { onSetActiveSubCategory, onSetActiveCategory, onSetActiveIndicator, onSetBivariateIndicator, onSetActiveGeography, onSetSelectedId } from '../../data/StatusStore';
import { index } from 'd3';
import { debounce } from 'lodash';


interface DataItem {
  id: string;
  value: any;
}

interface MenuBarProps {
  indicatorOptions: any;
  groupedModules: any;
  allData: any | null;
  chartId: string;
}

const MenuBar: FC<MenuBarProps> = ({
  indicatorOptions, groupedModules, allData, chartId
}) => {

  //const [selectedCategory, setSelectedCategory] = useState<string | null>("Basics");
  //const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>("American Human Development Index");
  // State to track hover status for each key
  const [hoverStates, setHoverStates] = useState<{ [key: string]: boolean }>({});
  const activeIndicator = useGlobalActiveIndicator();
  const bivariateIndicator = useGlobalBivariateIndicator();
  const activeGeography = useGlobalActiveGeography();
  const selectedId = useGlobalSelectedId();
  const selectedSubCategory = useGlobalActiveSubCategory();
  const selectedCategory = useGlobalActiveCategory();
  
  const handleMouseEnter = (key:string) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [key]: true, // Set the hover state of the specific key to true
    }));
  };

  const handleMouseLeave = (key:string) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [key]: false, // Set the hover state of the specific key to false
    }));
  };
  
  useEffect(() => {
    console.log("B061924 groupedModules",groupedModules);
    console.log("B061924 indicatorOptions",indicatorOptions);
  }, []);

  const handleClickCategory = (key:string) => {
    console.log("B061924 handleClickCategory key", key);
    onSetActiveCategory(key);
    onSetActiveSubCategory(Object.keys(groupedModules[key])[0]);
  };

  const handleClickSubCategory = (key:string) => {
    console.log("B061924 handleClickSubCategory key", key);
    onSetActiveSubCategory(key);
  };

  return (
    <div key={"menuBar"} style={{ height:"81px", width:'100%', backgroundColor:"#EFEFEF" }}>
      <div key={"menuBarChild"} style={{ height:"50px", width:'100%', backgroundColor:"#EFEFEF", display: "flex", justifyContent: "space-around" }}>
      {
        Object.keys(groupedModules).map((key) => {
          console.log(key);
            return (
            <div
              key={`menuBarItemCat_${key}`}
              style={{ 
                    cursor: 'pointer', fontSize: '10px', height: "100%", display: "flex", alignItems: "center", width: "100%",
                    justifyContent: "center", fontWeight: selectedCategory === key ? "bold" : "normal", 
                    textDecoration: selectedCategory === key ? "underline" : "none",
                    backgroundColor: hoverStates[key]
                      ? Colors.getColorByClass(key) // Background color on hover
                      : (selectedCategory === key ? Colors.getColorByClass(key) : 'transparent'), // Default or selected state color
                    color: key === "Basics" 
                            ? Colors.processBlack 
                            : hoverStates[key] 
                              ? Colors.white
                              : selectedCategory === key 
                                ? Colors.white
                                : Colors.getColorByClass(key),
                }}
                onClick={() => handleClickCategory(key)}
                onMouseEnter={() => handleMouseEnter(key)}
                onMouseLeave={() => handleMouseLeave(key)}     
            >
              {key}
            </div>
            )
        })
      }
      </div>
      <div style={{ height:"31px", width:'100%', backgroundColor:"#FAF8F8", display: "flex", justifyContent: "space-around" }}>
        {
          selectedCategory && Object.keys(groupedModules[selectedCategory]).map((key) => {
            console.log(key);
              return (
              <div
                key={`menuBarItemSubCat_${key}`}
                style={{ cursor: 'pointer', fontSize: '8px', height: "100%", display: "flex", alignItems: "center", 
                         justifyContent: "center", fontWeight: selectedSubCategory === key ? "bold" : "normal", textDecoration: selectedSubCategory === key ? "underline" : "none" }}
                onClick={() => {
                  // Add your click event logic here
                  handleClickSubCategory(key);
                }}
              >
                {key}
              </div>
              )
          })
        }
      </div>
    </div>
  );
};

export default MenuBar;
