// Comparisons.tsx
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useData, FlatFileCsvData} from '../data/DataContext';
//import { useStatus } from '../data/StatusContext';
import { MainHistogram } from '../components/structure/MainHistogram';
import ScatterPlot from '../components/charts/ScatterPlot';
import { MainMap } from '../components/maps/MainMap';
import IndicatorDropdown from '../components/inputs/IndicatorDropdown';
//import debounce from 'lodash.debounce';
import { Histogram } from '../components/charts/Histogram';
//import ChartList from '../components/structure/ChartList';
//import MenuBar from '../components/structure/MenuBar';
import { Selector } from '../components/inputs/Selector';
//import { on } from 'events';
import { useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalActiveGeography } from '../data/StatusStore';
import { useGlobalSwarmCalibration, onSetSwarmCalibration } from '../data/GlobalStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './views.scss';
import { BivariateLegend } from '../components/meta/BivariateLegend';
import _ from 'lodash';
import NumberPicker from "react-widgets/NumberPicker";
import "react-widgets/scss/styles.scss";
import { Combobox } from 'react-widgets/cjs';

export interface ComparisonsProps {
   // showMenu: boolean, 
   // setShowMenu: (showMenu: boolean) => void
}

const Comparisons: React.FC<ComparisonsProps> = ({/*showMenu, setShowMenu*/}) => {
  const { 
    dataActive, dataTract, dataPUMA, dataNYC, dataCD, dataBoro, isLoadingSorter,
    isLoadingTract, isLoadingPUMA, isLoadingNYC, isLoadingCD, isLoadingBoro, dataSorter,
    error } = useData();

    const [indicatorOptions, setIndicatorOptions] = useState<any | null>(null);
    const [groupedModules, setGroupedModules] = useState<any | null>(null);
    const [allData, setAllData] = useState<any | null>(null);
    
    const [mapType, setMapType] = useState<string>("bivariate - inverted");

    const activeIndicator = useGlobalActiveIndicator();
    const bivariateIndicator = useGlobalBivariateIndicator();
    const activeGeography = useGlobalActiveGeography();

    const swarmCalibration = useGlobalSwarmCalibration();

    const ref = React.useRef<HTMLDivElement>(null);
    
    const height = '200'
    const width = 'calc(100% - 0px)';

    useEffect(() => {
        if (!isLoadingBoro && !isLoadingCD && !isLoadingNYC && !isLoadingPUMA && !isLoadingTract) {
            if (dataBoro && dataCD && dataNYC && dataPUMA && dataTract) {
                console.log("dataBoro", dataBoro);
                console.log("dataCD", dataCD);
                console.log("dataNYC", dataNYC);
                console.log("dataPUMA", dataPUMA);
                console.log("dataTract", dataTract);
                let _indicatorOptions = [] as any;
                /*let _excludeOptions = ["", "DISPLAY_NAME"] as any;
                let testLimit = 0; 
                Object.keys(dataTract.meta.VARIABLE).forEach((i: string) => {
                    //if (testLimit < 3){
                        if (!_excludeOptions.includes(dataTract.meta.DISPLAY_NAME[i])){
                            _indicatorOptions.push(
                                { label: dataTract.meta.DISPLAY_NAME[i], value: i, type:"swarm" }
                            )
                            _indicatorOptions.push(
                                { label: dataTract.meta.DISPLAY_NAME[i], value: i, type:"histogram" }
                            )
                            testLimit++;
                        }
                    //}
                    
                })*/
                setAllData({
                    "Boro": dataBoro,
                    "CD": dataCD,
                    "NYC": dataNYC,
                    "PUMA": dataPUMA,
                    "Tract": dataTract
                });
                //setIndicatorOptions(_indicatorOptions)
            }
        }
    }, [dataBoro, isLoadingBoro, dataCD, isLoadingCD, dataNYC, isLoadingNYC, dataPUMA, isLoadingPUMA, dataTract, isLoadingTract]);
    
    /*useEffect(() => {
        if (!isLoadingBoro && !isLoadingCD && !isLoadingNYC && !isLoadingPUMA && !isLoadingTract && !isLoadingSorter) { 
            if (dataActive && dataSorter) {
                let _indicatorOptions = [] as any;
                let _classifiedIndicators = {} as any;
                console.log("A051124 dataSorter", dataSorter);
                const groupedData: any = {};
                dataSorter.forEach((data:any) => {
                    const category = data["Category 1"] || "Unknown";
                    const subCategory = data["SubCategory 1"] || "Unknown";
                    const module = data.Module || "Unknown";
                    const shape = data.Shape || "Unknown";
                    let sumTo100 = data["Sums to 100"] 
                        ? data["Sums to 100"] !== "Ungrouped" 
                            ? data["Sums to 100"]
                            : data["Sums to 100"] 
                        : "Ungrouped";
                    sumTo100 = shape.toLowerCase() === "pyramid" ? "Ungrouped" : sumTo100;
                    if (!groupedData[category]) {
                      groupedData[category] = {};
                    }
                
                    if (!groupedData[category][subCategory]) {
                      groupedData[category][subCategory] = {};
                    }
                
                    if (!groupedData[category][subCategory][module]) {
                      groupedData[category][subCategory][module] = {};
                    }
                
                    if (!groupedData[category][subCategory][module][sumTo100]) {
                      groupedData[category][subCategory][module][sumTo100] = {};
                    }
                    if (!groupedData[category][subCategory][module][sumTo100][shape]) {
                      groupedData[category][subCategory][module][sumTo100][shape] = [];
                    }
                    groupedData[category][subCategory][module][sumTo100][shape].push(data);
                });
                dataSorter.forEach((data:any) => {
                    const category = data["Category 2"] || null;
                    const subCategory = data["SubCategory 2"] || null;
                    if (category && subCategory){
                        if (category !== "" && subCategory !== ""){
                            const module = data.Module || "Unknown";
                            const shape = data.Shape || "Unknown";
                            let sumTo100 = data["Sums to 100"] 
                                ? data["Sums to 100"] !== "Ungrouped" 
                                    ? data["Sums to 100"]
                                    : data["Sums to 100"] 
                                : "Ungrouped";
                            sumTo100 = shape.toLowerCase() === "pyramid" ? "Ungrouped" : sumTo100;
                            if (!groupedData[category]) {
                            groupedData[category] = {};
                            }
                        
                            if (!groupedData[category][subCategory]) {
                            groupedData[category][subCategory] = {};
                            }
                        
                            if (!groupedData[category][subCategory][module]) {
                            groupedData[category][subCategory][module] = {};
                            }
                        
                            if (!groupedData[category][subCategory][module][sumTo100]) {
                            groupedData[category][subCategory][module][sumTo100] = {};
                            }
                            if (!groupedData[category][subCategory][module][sumTo100][shape]) {
                            groupedData[category][subCategory][module][sumTo100][shape] = [];
                            }
                            groupedData[category][subCategory][module][sumTo100][shape].push(data);
                        }
                    }
                });
                dataSorter.forEach((data:any) => {
                    const category = data["Category 3"] || null;
                    const subCategory = data["SubCategory 3"] || null;
                    if (category && subCategory){
                        if (category !== "" && subCategory !== ""){
                            const module = data.Module || "Unknown";
                            const shape = data.Shape || "Unknown";
                            let sumTo100 = data["Sums to 100"] 
                                ? data["Sums to 100"] !== "Ungrouped" 
                                    ? data["Sums to 100"]
                                    : data["Sums to 100"] 
                                : "Ungrouped";
                            sumTo100 = shape.toLowerCase() === "pyramid" ? "Ungrouped" : sumTo100;
                            if (!groupedData[category]) {
                            groupedData[category] = {};
                            }
                        
                            if (!groupedData[category][subCategory]) {
                            groupedData[category][subCategory] = {};
                            }
                        
                            if (!groupedData[category][subCategory][module]) {
                            groupedData[category][subCategory][module] = {};
                            }
                        
                            if (!groupedData[category][subCategory][module][sumTo100]) {
                            groupedData[category][subCategory][module][sumTo100] = {};
                            }
                            if (!groupedData[category][subCategory][module][sumTo100][shape]) {
                            groupedData[category][subCategory][module][sumTo100][shape] = [];
                            }
                            groupedData[category][subCategory][module][sumTo100][shape].push(data);
                        }
                    }
                });
                console.log("A051124 groupedData", groupedData);
                console.log("dataActive", dataActive);
                let _excludeOptions = ["", "DISPLAY_NAME"] as any;
                let testLimit = 0; 
                let autoGenerated = {
                    "Sub Category": {}
                } as any;
                Object.keys(dataActive.meta.VARIABLE).forEach((i: string) => {
                    //if (testLimit < 3){
                        if (!_excludeOptions.includes(dataActive.meta.DISPLAY_NAME[i])){
                            autoGenerated["Sub Category"][i] = {
                                "Ungrouped": {
                                    "beeswarm": [
                                        {
                                            "DISPLAY_NAME": dataActive.meta.DISPLAY_NAME[i],
                                            "VARIABLE": i,
                                            "Shape": "beeswarm",
                                            [activeGeography.toUpperCase()]: "X" // For auto generated modules, only those that exist per geo will be created, so just make up the X
                                        }
                                    ], 
                                    "histogram": [
                                        {
                                            "DISPLAY_NAME": dataActive.meta.DISPLAY_NAME[i],
                                            "VARIABLE": i,
                                            "Shape": "histogram",
                                            [activeGeography.toUpperCase()]: "X" // For auto generated modules, only those that exist per geo will be created, so just make up the X
                                        }
                                    ]
                                }
                            }
                            testLimit++;
                        }
                    //}
                    
                })
                groupedData["Auto Generated"] = autoGenerated;
                console.log("C052824 _indicatorOptions", _indicatorOptions);
                console.log("C052824 groupedData", groupedData);
                setIndicatorOptions(_indicatorOptions)
                setGroupedModules(groupedData);
            }
        }
    }, [dataActive]);*/

    useEffect(() => {
        console.log("indicatorOptions", indicatorOptions);
    }, [indicatorOptions]);

    useEffect(() => {
        console.log("groupedModules", groupedModules);
    }, [groupedModules]);

    useEffect(() => {
        console.log("activeGeography", activeGeography);
    }, [activeGeography]);

    useEffect(() => {
        console.log("allData", allData);
    }, [allData]);

    const memoizedScatter = useMemo(() => (
        console.log("A070724 allData", allData),
        console.log("A070724 activeIndicator", activeIndicator),
        console.log("A070724 activeGeography", activeGeography),
        //console.log("A070724 allData[activeGeography]", allData[activeGeography]),
        allData 
            ? <>
                <ScatterPlot 
                    key={`scatter_${activeIndicator}_${bivariateIndicator}_compare`} 
                    dataArrayX={allData[activeGeography]?.keyIndexDataArray[activeIndicator]}
                    dataArrayY={allData[activeGeography]?.keyIndexDataArray[bivariateIndicator]}
                    dataMeta={allData[activeGeography]?.meta}
                    histXMax={allData[activeGeography]?.meta?.max_value[activeIndicator]}
                    histXMin={allData[activeGeography]?.meta?.min_value[activeIndicator]}
                    histXNA={allData[activeGeography]?.meta?.na_count[activeIndicator]} 
                    histXLength={allData[activeGeography]?.meta?.length[activeIndicator]} 
                    histYMax={allData[activeGeography]?.meta?.max_value[bivariateIndicator]}
                    histYMin={allData[activeGeography]?.meta?.min_value[bivariateIndicator]}
                    histYNA={allData[activeGeography]?.meta?.na_count[bivariateIndicator]} 
                    histYLength={allData[activeGeography]?.meta?.length[bivariateIndicator]} 
                    allData={allData[activeGeography]} 
                    chartId={bivariateIndicator}
                    height={'700'}
                    width={'700'}
                    activeIndicator={activeIndicator}
                    bivariateIndicator={bivariateIndicator}
                    mapType={mapType}
                    radius={swarmCalibration?.ScatterPlot?.radius}
                />
            </>
            : null
      ), [activeIndicator, bivariateIndicator,  allData, activeGeography, height, width, mapType, swarmCalibration]); 

    if (isLoadingTract || isLoadingPUMA || isLoadingNYC || isLoadingCD || isLoadingBoro || !allData) {
        return <div className='main-container-loading'>
                <div className="loading-data">
                    Loading data.
                    <div className="loading-spinner">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                </div>
            </div>
    }
    if (error) return <p>Error loading map data: {error}</p>;
    console.log("A051024 swarmCalibration", swarmCalibration)
    return (
        <div className='main-container'>
            <div className='map-hist-container' id="mapHistContainer">
                <div
                    style={{
                        position: "absolute",
                        top: "33px",
                        //left: "calc(40% - 165px)",
                        right: "60%",
                        zIndex: "1000",
                        display: "grid",
                        fontSize: "10px",
                        opacity: "0.5",
                    }}>
                    <Selector 
                        options={[
                            {"label":"Choropleth", "value":"choropleth"},
                            {"label":"Bivariate Standard", "value":"bivariate - standard"},
                            {"label":"Bivariate Inverted", "value":"bivariate - inverted"} 
                        ]}
                        onChange={(e: any) => {
                            console.log("e", e);
                            setMapType(e)
                        }}
                        placeholder={"Bivariate Inverted"} 
                    />
                    <div     
                        style={{
                            width:"111px",
                            backgroundColor: "white",
                            float:"right",
                        }}>
                        <div
                            style={{
                                width:"111px",
                                fontSize:"8px",
                                backgroundColor: "white",
                            }}
                        >Scatterplot Radius</div>
                        <NumberPicker
                            style={{
                                width:"111px",
                                fontSize:"10px",
                            }}
                            //defaultValue={0.75}
                            value={swarmCalibration?.ScatterPlot?.radius}
                            step={1}
                            onChange={(e) => {
                                console.log("A051024 e", e )
                                onSetSwarmCalibration({
                                    ...swarmCalibration,
                                    ["ScatterPlot"]: {
                                        radius:e,
                                    }
                                });
                            }}
                        /> 
                    </div>
                </div>
                <MainMap 
                    keyIndexDataArray={dataActive?.keyIndexDataArray}
                    dataArray={dataActive?.dataArray}
                    dataJSON={dataActive?.dataJSON}
                    dataMeta={dataActive?.meta}
                    chartId={"main-map-comparisons"}
                    height={'calc(100% - 350px)'}
                    width={'100%'}
                    mapType={mapType}
                />
                <div style={{position:"relative", height: "350px"}} className='main-histogram-container'> 
                    {mapType === "choropleth"
                        ? <MainHistogram  
                                dataGeo={dataActive}
                                histMax={allData[activeGeography]?.meta?.max_value}
                                histNA={allData[activeGeography]?.meta?.na_count} 
                                histLength={allData[activeGeography]?.meta?.length} 
                                chartId={"main-hist"}
                            />
                        : <BivariateLegend
                                dataGeo={dataActive}
                                histMax={allData[activeGeography]?.meta?.max_value}
                                histNA={allData[activeGeography]?.meta?.na_count} 
                                histLength={allData[activeGeography]?.meta?.length} 
                                chartId={"main-hist"}
                                height={'180'}
                                width={'calc(100% - 20px)'}
                                mapType={mapType}
                                activeIndicator={activeIndicator}
                                bivariateIndicator={bivariateIndicator}
                            />
                    }
                </div>
            </div>
            <div className='featured-view-container'>
                {/*<MenuBar
                    key={`menu-bar`}
                    indicatorOptions={indicatorOptions}
                    groupedModules={groupedModules}
                    allData={allData}
                    chartId={"main-hist"}
                />
                <Selector 
                    options={[
                        {"label":"Tract", "value":"Tract"},
                        {"label":"PUMA", "value":"PUMA"},
                        {"label":"CD", "value":"CD"},
                        {"label":"Boro", "value":"Boro"},
                        {"label":"NYC", "value":"NYC"} 
                    ]}
                    onChange={handleGeographyChange}
                    placeholder={activeGeography} 
                    />
                <Selector 
                    options={indicatorOptions ? indicatorOptions : []}
                    onChange={handleIndicatorChange}
                    placeholder={activeIndicator} 
                />
                {indicatorOptions 
                ?   <ChartList
                        key={`chart-list`}
                        indicatorOptions={indicatorOptions}
                        groupedModules={groupedModules}
                        allData={allData}
                        chartId={"main-hist"}
                    />
                : null}*/
                
                }
                <div
                //key={`chart-card-${index}-${key}`}
                ref={ref}
                style={{
                    height: "100%", //'200px',
                    paddingTop: '20px',
                    borderTop: '1px solid #ccc',
                    backgroundColor: "white", 
                    overflowY: "hidden", //"scroll", 
                    overflowX: "hidden", 
                }}>
                    <IndicatorDropdown options={allData[activeGeography].meta.DISPLAY_NAME} />
                {/* <div
                    style={{
                        fontSize: "10px",
                        lineHeight: "19px",
                        color: "gray",
                        backgroundColor: "white",
                        position:"absolute",
                        top: "1px",
                        height:"19px",
                        borderBottom: "1px solid #e8e4e4",
                        width: "100%",
                        zIndex: "1",
                    }}
                    >{chartIndicatorName}</div>*/
                    

                    memoizedScatter
                    }
                </div>
            </div>
        </div>
    );
};

export default Comparisons;
