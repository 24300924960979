import React, { useState, useEffect, FC } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { feature } from 'topojson-client';
import 'leaflet/dist/leaflet.css';
import { GeoJsonObject, FeatureCollection } from 'geojson';
import { useData } from '../../../data/DataContext';
import Colors from '../../utilities/Colors'
import { onHoverHistogram, onClickHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveGeography, useGlobalActiveIndicator , useGlobalBivariateIndicator, useGlobalBivariateOverRide } from '../../../data/StatusStore';

interface DataItem {
    id: string;
    value: any;
}

interface ChoroplethLayerProps {
    _dataArray: any;
    _keyIndexDataArray: any;
    dataJSON: any;
    dataMeta: any;
    //activeGeography: string;
    //activeIndicator: string;
    chartId: string;
    mapType: string;
    //onHover: (e: any) => void;
    //onClick: (e: any) => void;
    //hoveredId: string | null;
    //selectedId: string | null;
}

export const ChoroplethLayer: FC<ChoroplethLayerProps> = (
    { _dataArray, _keyIndexDataArray, dataJSON, dataMeta, /*activeGeography, activeIndicator,*/ chartId, mapType,
        /*, onHover, onClick, hoveredId, selectedId*/}
) => {
    const hoveredId = useGlobalHoveredId();
    const selectedId = useGlobalSelectedId();
    const activeIndicator = useGlobalActiveIndicator();
    const bivariateIndicator = useGlobalBivariateIndicator();
    const bivariateOverRide = useGlobalBivariateOverRide();
    const activeGeography = useGlobalActiveGeography();

    const [activeGeoJSON, setActiveGeoJSON] = useState<FeatureCollection | null>(null);
    const [mapId, setMapId] = useState<number>(0);
    //const [maxValue, setActiveGeoJSON] = useState<FeatureCollection | null>(null);

    //let bivariateIndicator = "age_pyramid_total";

    const { 
        geoJsonDataPUMA, geoJsonDataTract,
        isLoadingTopology, 
        error 
    } = useData();

    useEffect(() => {
        // nothing for now
    }, []);

    useEffect(() => {
        console.log("D041824 _keyIndexDataArray", _keyIndexDataArray);
        console.log("D041824 _dataArray", _dataArray);
        console.log("D041824 activeIndicator", activeIndicator);
        console.log("D041824 _dataArray[activeIndicator]", _dataArray[activeIndicator]);
        console.log("D041824 dataMeta", dataMeta);
        console.log("D041824 activeGeography", activeGeography);
        if (_dataArray && _keyIndexDataArray && dataMeta) {
            const dataArray = _dataArray[activeIndicator];
            const keyIndexDataArray = _keyIndexDataArray[activeIndicator];
            let dataArray2:any = null;
            let keyIndexDataArray2:any = null;
            if (mapType === "bivariate - standard" || mapType === "bivariate - inverted") {
                dataArray2 = _dataArray[bivariateIndicator];
                keyIndexDataArray2 = _keyIndexDataArray[bivariateIndicator];
            };
            console.log("D041824 keyIndexDataArray", keyIndexDataArray);
            console.log("D041824 dataArray", dataArray);
            console.log("D041824 dataMeta", dataMeta);
            if (keyIndexDataArray && dataArray && dataMeta) {
                let tempGeoJSON = geoJsonDataTract;
                if (activeGeography === "PUMA" || activeGeography === "CD") {
                    tempGeoJSON = geoJsonDataPUMA;
                }else if (activeGeography === "Tract") {
                    tempGeoJSON = geoJsonDataTract;
                }
                if (tempGeoJSON){
                    tempGeoJSON.features.forEach((feature: any) => {
                        //console.log("D041824 feature", feature);
                        if (keyIndexDataArray[feature.properties.GEOID10]){
                            feature.properties["value"] = keyIndexDataArray[feature.properties.GEOID10]?.value;
                            feature.properties["index"] = keyIndexDataArray[feature.properties.GEOID10]?.index;
                            if (mapType === "bivariate - standard") {
                                feature.properties["color"] = Colors.getStandardBivariateColor(
                                    keyIndexDataArray[feature.properties.GEOID10].index,
                                    dataMeta.na_count[activeIndicator],
                                    dataArray.length,
                                    keyIndexDataArray2[feature.properties.GEOID10].index,
                                    dataMeta.na_count[bivariateIndicator],
                                    dataArray2.length,
                                );
                            } else if (mapType === "bivariate - inverted") {
                                feature.properties["color"] = Colors.getInvertedBivariateColor(
                                    keyIndexDataArray[feature.properties.GEOID10].index,
                                    dataMeta.na_count[activeIndicator],
                                    dataArray.length,
                                    keyIndexDataArray2[feature.properties.GEOID10].index,
                                    dataMeta.na_count[bivariateIndicator],
                                    dataArray2.length,
                                    bivariateOverRide, 
                                    keyIndexDataArray2[feature.properties.GEOID10].value, 
                                    keyIndexDataArray[feature.properties.GEOID10].value 
                                );
                            } else {
                                feature.properties["color"] = Colors.getColorQuintile(keyIndexDataArray[feature.properties.GEOID10].index, dataMeta.na_count[activeIndicator], dataArray.length);
                            }
                        }
                    });
                }
                setActiveGeoJSON(tempGeoJSON);
                setMapId(mapId + 1);
            }
        }
    }, [activeIndicator, bivariateIndicator, bivariateOverRide /*activeGeography,*/, _keyIndexDataArray, mapType, _dataArray]);

    useEffect(() => {
        console.log("activeGeoJSON",activeGeoJSON);
    }, [activeGeoJSON]);

    const onEachFeature = (feature: any, layer: L.Layer) => {
        layer.on('mouseover', () => {
            onHoverHistogram(feature?.properties.GEOID10 ? feature.properties.GEOID10 : null);
        });
        layer.on('click', () => {
            onClickHistogram(feature?.properties.GEOID10 ? feature.properties.GEOID10 : null);
        });
    };

    const style = (feature: any) => {
        //let color = getColorQuintile()
        return {
            color: '#ffffff',
            weight: 0.15,
            fillColor: feature.properties.GEOID10 === hoveredId 
                ? Colors.highlightHover 
                : feature.properties.GEOID10 === selectedId 
                    ? Colors.highlightSelect 
                    : feature.properties.color, // Change fill color based on hover/selcetion
            fillOpacity: 0.75,
            smoothFactor:0,
        };
    };

    return activeGeoJSON ? (
        <GeoJSON
            key={`map${mapId}`}
            data={activeGeoJSON}
            style={style}
            onEachFeature={onEachFeature}
        />
    ) : null;
};