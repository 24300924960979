import React, { useState, useEffect, useRef } from 'react';
import Colors from '../utilities/Colors';
import { onHoverHistogram, onClickHistogram, onSetActiveIndicator } from '../../data/StatusStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface DataItem {
  id: string;
  value: any;
}

export interface HistogramStaticProps {
  dataArray: DataItem[];
  histMax: number;
  histNA: number;
  histLength: number;
  chartId: string;
  height: string;
  width: string;
  paddingLeft: string;
  paddingRight: string;
  colorClass: string;
}

export const HistogramStatic: React.FC<HistogramStaticProps> = ({
  dataArray, histMax, histNA, histLength, chartId, height, width, paddingRight, paddingLeft, colorClass
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  console.log("A062424 HistogramStatic dataArray", dataArray);
  console.log("A062424 HistogramStatic chartId", chartId);
  useEffect(() => {
    const updateContainerWidth = () => {
      if (svgRef.current) {
        setContainerWidth(svgRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener('resize', updateContainerWidth);
    updateContainerWidth(); // Initial update

    return () => window.removeEventListener('resize', updateContainerWidth);
  }, []);

  useEffect(() => {
    // Assuming the loading state can be determined by checking if dataArray is populated
    if (dataArray && dataArray.length > 0) {
      setIsLoading(false);
    }
  }, [dataArray]); // Watch dataArray for changes

  /*const onClicked = (variable: string) => {
    console.log('Clicked globe variable:', variable);
    onSetActiveIndicator(variable);
  };*/

  const viewBoxWidth = histLength * 10;
  const _height = parseFloat(height);

  return (
    <div className='histogram-container'
      /*style={{
        paddingRight: paddingRight,
        paddingLeft: paddingLeft,
      }}*/
    >
      {isLoading ? (
        <div className="loading-spinner">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        dataArray
        ? <svg ref={svgRef} width={width} height={`${height}px`} viewBox={`0 0 ${viewBoxWidth} ${height}`} preserveAspectRatio="none" className='hist-container' 
          style={{
            paddingRight: paddingRight,
            paddingLeft: paddingLeft,
          }}>
          {dataArray.map((d, i) => {
            const normalizedValue = d.value === "n/a" ? 1 : d.value;
            const barHeight = (normalizedValue / histMax) * _height;
            const _color = Colors.getColorQuintile(i, histNA, histLength, colorClass);
            return (
              <rect
                key={`${chartId}_${i}`}
                x={i * 10}
                y={_height - barHeight}
                width="9"
                height={barHeight}
                fill={_color}
                onMouseOver={() => onHoverHistogram(d.id)}
                onClick={() => {
                    onClickHistogram(d.id)
                    //onSetActiveIndicator(chartId)
                    onSetActiveIndicator(chartId.replace("_stacked_hist", ""))
                }}
                className="hist-bar"
              />
            );
          })}
        </svg>
        : null
      )}
    </div>
  );
};
