interface D2gRoundOptions {
    currency?: boolean;
    override?: [boolean, number];
    returnString?: boolean;
}

export const d2gRound = (
    number: number,
    options: D2gRoundOptions = {}
): string | number => {
    // Destructure options with default values
    const {
        currency = false,
        override = [false, 1],
        returnString = false
    } = options;

    let rounded: number | string;

    if (typeof number === 'number') {
        rounded = number;
    } else {
        rounded = parseFloat(number);
    }

    if (override[0]) {
        rounded = parseFloat(rounded.toFixed(override[1]));
    } else if (currency) {
        if (returnString) {
            rounded = rounded.toFixed(2);
        } else {
            rounded = parseFloat(rounded.toFixed(2));
        }
    } else if (Math.abs(rounded) < 10) {
        rounded = parseFloat(rounded.toFixed(2));
    } else if (Math.abs(rounded) < 100) {
        rounded = parseFloat(rounded.toFixed(1));
    } else {
        if (returnString) {
            rounded = Math.round(rounded).toLocaleString(undefined, { maximumFractionDigits: 0 });
        } else {
            rounded = Math.round(rounded);
        }
    }

    if (returnString) {
        rounded = rounded.toString();
    }

    return rounded;
}

export function getInvertedBivariateColorBreaks(
    maxX: number, minX: number, recordCountX: number, dataX: any,
    maxY: number, minY: number, recordCountY: number, dataY: any,
    allData: any, bivariateIndicator: string, activeIndicator: string, 
  ) {
    console.log("D070824 recordCountX, minX", recordCountX, minX);
    console.log("D070824 recordCountY, minY", recordCountY, minY);
    let xInd = bivariateIndicator;
    let yInd = activeIndicator;
    recordCountX = recordCountX - minX;
    recordCountY = recordCountY - minY;
  
    // Calculate the quantiles for the breaks
    const _quantileX1 = allData.dataArray[xInd][Math.ceil(recordCountX / 3)];
    const _quantileX2 = allData.dataArray[xInd][Math.ceil((recordCountX / 3) * 2)];
    const _quantileY1 = allData.dataArray[yInd][Math.ceil(recordCountY / 3)];
    const _quantileY2 = allData.dataArray[yInd][Math.ceil((recordCountY / 3) * 2)];

    console.log("D070824 recordCountX", recordCountX);
    console.log("D070824 recordCountY", recordCountY);
    console.log("D070824 allData.dataArray[yInd]", allData.dataArray[xInd]);
    console.log("D070824 allData.dataArray[yInd]", allData.dataArray[yInd]);
    console.log("D070824 _quantileX1", _quantileX1);
    console.log("D070824 _quantileX2", _quantileX2);
    console.log("D070824 _quantileY1", _quantileY1);
    console.log("D070824 _quantileY2", _quantileY2);

    return {
      xBreaks: [_quantileX1?.value ? _quantileX1?.value : null, _quantileX2?.value ? _quantileX2?.value : null], // Breaks for the X axis (value)
      yBreaks: [_quantileY1?.value ? _quantileY1?.value : null, _quantileY2?.value ? _quantileY2?.value : null]  // Breaks for the Y axis (value2)
    };
  }

const PUMA_to_CD: { [key: string]: string[] } = {
    '3603810':['101','102'], //'3603810':'102',
    '3603809':['103'],
    '3603807':['104','105'], //'3603807':['105',
    '3603808':['106'],
    '3603806':['107'],
    '3603805':['108'],
    '3603802':['109'],
    '3603803':['110'],
    '3603804':['111'],
    '3603801':['112'],
    '3603710':['201','202'], //'3603710':['202',
    '3603705':['203','206'], //'3603705':['206',
    '3603708':['204'],
    '3603707':['205'],
    '3603706':['207'],
    '3603701':['208'],
    '3603709':['209'],
    '3603703':['210'],
    '3603704':['211'],
    '3603702':['212'],
    '3604001':['301'],
    '3604004':['302'],
    '3604003':['303'],
    '3604002':['304'],
    '3604008':['305'],
    '3604005':['306'],
    '3604012':['307'],
    '3604006':['308'],
    '3604011':['309'],
    '3604013':['310'],
    '3604017':['311'],
    '3604014':['312'],
    '3604018':['313'],
    '3604015':['314'],
    '3604016':['315'],
    '3604007':['316'],
    '3604010':['317'],
    '3604009':['318'],
    '3604101':['401'],
    '3604109':['402'],
    '3604102':['403'],
    '3604107':['404'],
    '3604110':['405'],
    '3604108':['406'],
    '3604103':['407'],
    '3604106':['408'],
    '3604111':['409'],
    '3604113':['410'],
    '3604104':['411'],
    '3604112':['412'],
    '3604105':['413'],
    '3604114':['414'],
    '3603903':['501'],
    '3603902':['502'],
    '3603901':['503']
}

const CD_to_PUMA = {
'101':'3603810',
'102':'3603810',
'103':'3603809',
'104':'3603807',
'105':'3603807',
'106':'3603808',
'107':'3603806',
'108':'3603805',
'109':'3603802',
'110':'3603803',
'111':'3603804',
'112':'3603801',
'201':'3603710',
'202':'3603710',
'203':'3603705',
'204':'3603708',
'205':'3603707',
'206':'3603705',
'207':'3603706',
'208':'3603701',
'209':'3603709',
'210':'3603703',
'211':'3603704',
'212':'3603702',
'301':'3604001',
'302':'3604004',
'303':'3604003',
'304':'3604002',
'305':'3604008',
'306':'3604005',
'307':'3604012',
'308':'3604006',
'309':'3604011',
'310':'3604013',
'311':'3604017',
'312':'3604014',
'313':'3604018',
'314':'3604015',
'315':'3604016',
'316':'3604007',
'317':'3604010',
'318':'3604009',
'401':'3604101',
'402':'3604109',
'403':'3604102',
'404':'3604107',
'405':'3604110',
'406':'3604108',
'407':'3604103',
'408':'3604106',
'409':'3604111',
'410':'3604113',
'411':'3604104',
'412':'3604112',
'413':'3604105',
'414':'3604114',
'501':'3603903',
'502':'3603902',
'503':'3603901'
}

export function crosswalkPUMAtoCD(puma: string) {
    console.log("081624 puma", puma);
    console.log("081624 PUMA_to_CD", PUMA_to_CD);
    console.log("081624 PUMA_to_CD[puma]", PUMA_to_CD[puma]);
    //console.log("081624 CD_to_PUMA", CD_to_PUMA);
    return PUMA_to_CD[puma];
  }